import React, { useState, useEffect } from "react";
import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";

import SecondaryLanding from "../../../layouts/secondary-landing";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import Button from "../../../components/custom-widgets/button";
import List from "../../../components/custom-widgets/list";
import { first, filter } from "lodash";

// Font Awesome Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let status = "Loading...";
const initialRateData = [
  {
    LoanProgram: "30-Year Fixed Custom Construction/Remodel",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "Home Equity Line of Credit (HELOC)",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "30-Year Fixed Refinance",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "30-Year Fixed Smart Start",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "30-Year Fixed Mortgage",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  },
  {
    LoanProgram: "HELOAN",
    InterestRate: status,
    APR: status,
    Fee: status,
    PIPayments: status
  }
];

const MortgageRates = () => {
  const [data, setData] = useState(initialRateData);
  const [isError, setIsError] = useState(false);

  const getRateByName = (rateName) => {
    return first(
      filter(data, (rate) => {
        return rate.LoanProgram === rateName;
      })
    );
  };
  const rateThirtyYearFixed = getRateByName("30-Year Fixed Mortgage");
  const rateThirtyYearFixedConstruction = getRateByName("30-Year Fixed Custom Construction/Remodel");
  const rateTenYearInterestOnlyHeloc = getRateByName("Home Equity Line of Credit (HELOC)");
  const rateThirtyYearFixedRefinance = getRateByName("30-Year Fixed Refinance");
  const rateThirtyYearFixedSmartStart = getRateByName("30-Year Fixed Smart Start");
  const rateHELOAN = getRateByName("HELOAN");

  // TODO: maybe could move this to helpers folder for reuse elsewhere
  const formatCurrency = (value) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2
    }).format(value);
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Rates"
    }
  ];
  const title = "Current Mortgage Interest Rates";

  const SEOData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Check out WaFD Bank's current mortgage interest rates on fixed mortgages, adjustable rate mortgages, custom construction loans, lot loans, and interest only HELOC. Find the best mortgage loan for you!"
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/mortgage-rates"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        "http-equiv": "cache-control",
        content: "no-cache"
      }
    ]
  };

  function renderRateRow(id, title, rateRecord) {
    if (!rateRecord) {
      return status;
    }
    return (
      <tr>
        <td id={id} dangerouslySetInnerHTML={{ __html: title }} />
        <td id={id + `-interest-rate`}>
          {isError
            ? "Not Available"
            : typeof rateRecord.InterestRate === "number"
            ? `${rateRecord.InterestRate.toFixed(3)}%`
            : rateRecord.InterestRate}
        </td>
        <td id={id + `-pi-payments`}>
          {isError
            ? "Not Available"
            : typeof rateRecord.PIPayments === "number"
            ? formatCurrency(rateRecord.PIPayments)
            : rateRecord.PIPayments}
        </td>
        <td id={id + `-fee`}>
          {isError
            ? "Not Available"
            : typeof rateRecord.Fee === "number"
            ? `${rateRecord.Fee.toFixed(3)}%`
            : rateRecord.Fee}
        </td>
        <td id={id + `-apr`}>
          {isError
            ? "Not Available"
            : typeof rateRecord.APR === "number"
            ? `${rateRecord.APR.toFixed(3)}%`
            : rateRecord.APR}
        </td>
      </tr>
    );
  }

  const GreenCheckmark = () => (
    <span className="fa-li">
      <FontAwesomeIcon className="mr-2 text-success" icon={["fas", "check"]} />
    </span>
  );

  const fetchData = () => {
    const host = window.location.hostname === "www.wafdbank.com" ? "https://wafdapi.com" : "https://test.wafdapi.com";
    axios
      .get(`${host}/mortgage-rates.json`)
      .then((response) => {
        setData(response.data);
      })
      .catch(function (error) {
        console.log("error", error);
        setIsError(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const buyingHomeListData = {
    greenCircleCheckmark: true,
    accountName: "buying-a-home",
    items: [
      {
        id: 1,
        text: "Ask us about additional available loan programs"
      },
      {
        id: 2,
        text: "Subject to credit approval"
      },
      {
        id: 3,
        text: "APR is based on loan amount and interest rate"
      },
      {
        id: 4,
        text: "Payments quoted do not include taxes & insurance, so actual payments may be higher"
      }
    ]
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Current Mortgage Interest Rates</h1>
        <div className="table-responsive mb-3">
          <table className="table table-bordered mortgage-rates" title="Mortgage Rates">
            <thead>
              <tr className="text-center d-none d-md-table-row">
                <th>
                  <strong>
                    Mortgage Loan
                    <br />
                    Program
                  </strong>
                </th>
                <th>
                  <strong>Interest Rate</strong>
                </th>
                <th>
                  <strong>P&amp;I Payments</strong>
                </th>
                <th>
                  <strong>Fee</strong>
                </th>
                <th>
                  <strong>APR</strong>
                  <sup>&dagger;</sup>
                </th>
              </tr>
              <tr className="text-center d-table-row d-md-none">
                <th>
                  <p className="lead text-black">
                    <strong>
                      Mortgage Loan
                      <br />
                      Program
                    </strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Interest Rate</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>P&amp;I Payments</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Fee</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>APR</strong>
                    <sup>&dagger;</sup>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderRateRow("30-year-fixed-mortgage", "30-Year Fixed Mortgage<sup>1</sup>", rateThirtyYearFixed)}
              {renderRateRow(
                "30-year-fixed-construction",
                "30-Year Fixed Custom Construction / Remodel<sup>2</sup>",
                rateThirtyYearFixedConstruction
              )}
              {renderRateRow(
                "30-year-fixed-refinance",
                "30-Year Fixed Refinance<sup>3</sup>",
                rateThirtyYearFixedRefinance
              )}
              {renderRateRow(
                "30-year-fixed-smart-start",
                "30-Year Fixed Smart Start<sup>4</sup>",
                rateThirtyYearFixedSmartStart
              )}
              {renderRateRow("heloan", "HELOAN<sup>5</sup>", rateHELOAN)}
            </tbody>
          </table>
        </div>
        <h2>Interest Only Loan Programs</h2>
        <div className="table-responsive">
          <table className="table table-bordered mortgage-rates" title="Mortgage Rates">
            <thead>
              <tr className="text-center d-none d-md-table-row">
                <th>
                  <strong>
                    Mortgage Loan
                    <br />
                    Program
                  </strong>
                </th>
                <th>
                  <strong>Interest Rate</strong>
                </th>
                <th>
                  <strong>Interest Only Payments</strong>
                </th>
                <th>
                  <strong>Fee</strong>
                </th>
                <th>
                  <strong>APR</strong>
                  <sup>&dagger;</sup>
                </th>
              </tr>
              <tr className="text-center d-table-row d-md-none">
                <th>
                  <p className="lead text-black">
                    <strong>
                      Mortgage Loan
                      <br />
                      Program
                    </strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Interest Rate</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Interest Only Payments</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>Fee</strong>
                  </p>
                </th>
                <th>
                  <p className="lead text-black">
                    <strong>APR</strong>
                    <sup>&dagger;</sup>
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderRateRow(
                "heloc-interest-only-loan",
                "Home Equity Line of Credit (HELOC)<sup>6</sup>",
                rateTenYearInterestOnlyHeloc
              )}
            </tbody>
          </table>
        </div>
      </section>

      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 mb-md-0">
              <StaticImage
                src="../../../images/thumbnail-mortgage-rates-062424.jpg"
                alt="A couple sitting on the floor of their new house surrounded by boxes."
                placeholder="blurred"
                loading="eager"
                quality="100"
              />
            </div>
            <div className="col-md-6">
              <h2 className="text-success">Buying a New Home?</h2>
              <List {...buyingHomeListData} />
              <Button
                url="/personal-banking/contact-loan-officer"
                text="Find a Loan Officer Near You"
                showIcon={false}
                class="btn-primary w-100 w-sm-auto"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <p id="apr-disclaimer" className="text-muted">
          <sup>&dagger;</sup> Annual Percentage Rate (APR) The cost to borrow money expressed as a yearly percentage.
          For mortgage loans, excluding home equity lines of credit, it includes the interest rate plus other charges or
          fees. For home equity lines, the APR is just the interest rate.
        </p>
        <p id="30-year-fixed-mortgage-disclaimer" className="text-muted">
          <sup>1</sup> Assumes a purchase of an owner occupied single family residence with a loan amount of $320,000
          based on a purchase price of $400,000 with a 20% down payment of $80,000. Borrower FICO score of 750 or
          higher. Subject to qualification. Interest rate applies to 30 year fixed purchase only program. Monthly
          payment based on a 30 year loan term amortized over 360 months. Contact your neighborhood loan officer for
          details on your loan scenario.
        </p>
        <p id="30-year-fixed-construction-disclaimer" className="text-muted">
          <sup>2</sup> Assumes a Custom Construction / Remodel of an owner occupied single family residence with a loan
          amount of $320,000 based on a finished value and cost to complete Custom Construction / Remodel of $400,000.
          Borrower FICO score of 750 or higher. Subject to qualification. Interest rate applies only to 30 year fixed
          Custom Construction / Remodel program. Monthly payment based on a 30 year loan term amortized over 360 months.
          Contact your neighborhood loan officer for details on your loan scenario. This product is not available in
          California.
        </p>
        <p id="30-year-fixed-refinance-disclaimer" className="text-muted">
          <sup>3</sup> Actual rates may vary. Assumes a no-cash out refinance of an owner occupied single family
          residence with a loan amount of $320,000 based on a property appraised value of $400,000 with 20% equity.
          Borrower FICO score of 750 or higher. Subject to qualification. Interest rate applies to a 30 year fixed rate
          refinance program. Cash-out Refinance options may be available with different rates and terms. Contact your
          neighborhood loan officer for details on your loan scenario.
        </p>
        <p id="30-year-fixed-smart-start-disclaimer" className="text-muted">
          <sup>4</sup> Qualified borrowers must meet eligibility requirements including, but not limited to, being
          owner-occupants, meeting certain qualifying income limitations and purchasing a home within a certain
          geographical area. Maximum loan to value is 97% and maximum loan amount is $766,550. Minimum 3% down payment
          is required. Borrower(s) FICO score of 640 or higher. Subject to qualification and credit approval. Interest
          rate applies to a 30 year fixed purchase only program. Monthly payments based on a 30 year loan term amortized
          over 360 months.
        </p>
        <p id="heloan-disclaimer" className="text-muted">
          <sup>5</sup> Subject to credit approval. HELOAN assumes a $100,000 amount and that the (1) borrower has an
          existing WaFd Bank first mortgage, (2) that the subject property is owner occupied, (3) borrowers have FICOs
          of 750 or higher, (4) the combined loan to value is maximum of 60%. Monthly payment based on a 20 year loan
          term amortized over 240 months. Contact your neighborhood loan officer for details on your loan scenario.
        </p>
        <p id="heloc-interest-only-loan-disclaimer" className="text-muted">
          <sup>6</sup> Subject to credit approval. HELOC interest rate and fee assumes the line of credit is $100,000
          and that the (1) borrower has an existing WaFd Bank first mortgage, (2) that the subject property is owner
          occupied, (3) borrowers have FICO scores of 750 or higher, (4) the combined loan to value is maximum of 60%,
          and (5) payments are made via EZ-Pay from a WaFd Bank checking account.
        </p>
      </section>
    </SecondaryLanding>
  );
};

export default MortgageRates;
